.link() {
  text-decoration: var(--link-text-decoration);
  font-weight: var(--link-font-weight);

  &:link {
    color: var(--link-color);
  }

  &:visited {
    color: var(--link-visited-color);
  }

  &:hover,
  &:focus {
    color: var(--link-color--hover);
    text-decoration: var(--link-text-decoration--hover);
  }

  &:active {
    color: var(--link-color--active);
    text-decoration: var(--link-text-decoration--active);
  }
}

.link-dark() {
  text-decoration: var(--link-dark-text-decoration);
  font-weight: var(--link-dark-font-weight);

  &:link {
    color: var(--link-dark-color);
  }

  &:visited {
    color: var(--link-dark-visited-color);
  }

  &:hover,
  &:focus {
    color: var(--link-dark-color--hover);
    text-decoration: var(--link-dark-text-decoration--hover);
  }

  &:active {
    color: var(--link-dark-color--active);
    text-decoration: var(--link-dark-text-decoration--active);
  }
}

.item() {
  position: relative;
  z-index: 0;

  &::before,
  &::after {
    position: absolute;
    content: "";
    inset: 0;
    z-index: -1;
    border-radius: 0.66rem;
    transition: var(--standard-transition);
    pointer-events: none;
  }

  &::before {
    background-color: transparent;
  }

  &::after {
    box-shadow: 0 0 0 0.66rem transparent;
  }

  &:hover,
  &:focus {
    &::before {
      background-color: var(--item-background--hover);
    }
  }

  &:active,
  &:focus {
    outline: none !important;
    z-index: 1;

    &::before {
      background-color: var(--item-background--active);
    }

    &::after {
      box-shadow: 0 0 0 0.33rem var(--item-box-shadow--focus);
    }
  }

  &:focus-visible {
    &::after {
      box-shadow: 0 0 0 0.2rem var(--text-color) !important;
      opacity: 1 !important;
    }
  }
}
